import { staticConstants } from '../../utils/constants';
// eslint-disable-next-line import/extensions
import actionCreator from '../../actioncreators/login.js';
import Translator from '../../utils/translation/Translator';
import translationUtils from '../../utils/translation/utility';
import UtilityHelper from '../../utils/helpers';
import cookieUtils from '../../utils/cookie';
import { LengthRule } from '../../utils/validator/rules';
import { supportedErrorCodes } from '../../utils/http/HttpClient';
import namespaceTranslatedText from '../TranslatedText';
import SpinnerIcon from '../../../static/assets/images/static/spinner.svg';
import {
  ButtonContainer,
  TextInput,
  PasswordInput,
  InlineMessage,
  PrimaryButton,
  CaptchaInput,
} from '../../components';
import Link from '../../components/elements/links/Link';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TranslatedText = namespaceTranslatedText('LOGIN');
const loginDictionary = new Translator('LOGIN');
const textInputDictionary = new Translator('TEXT_INPUT');
const notValidatedPwInputDictionary = new Translator('NOT_VALIDATED_PW_INPUT');

const SecureInfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height="2em"
    width="1.5em"
    fill="#938C85"
    focusable="false"
    style={{
      verticalAlign: 'text-bottom',
      display: 'inline-block',
      overflow: 'visible',
    }}
  >
    <g>
      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z" />
    </g>
  </svg>
);

class Login extends Component {
  static propTypes = {
    formUsername: PropTypes.string.isRequired,
    formPassword: PropTypes.string.isRequired,
    formRecaptchaToken: PropTypes.string,
    isErrorVisible: PropTypes.bool.isRequired,
    errorCode: PropTypes.string,
    isValidating: PropTypes.bool.isRequired,
    isAllValid: PropTypes.bool.isRequired,
    isCheckingAuth: PropTypes.bool.isRequired,
    isCheckingUsername: PropTypes.bool.isRequired,
    isAuthenticatedByNexo: PropTypes.bool.isRequired,
    isLoggingIn: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    captchaRequired: PropTypes.bool.isRequired,
    recaptchaSiteKey: PropTypes.string,
    showPasswordField: PropTypes.bool.isRequired,
    isRebrandEnabled: PropTypes.bool,

    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchSetFormFieldValidationResult: PropTypes.func.isRequired,
    dispatchLogin: PropTypes.func.isRequired,
    dispatchHidePasswordInputDisplay: PropTypes.func.isRequired,
    dispatchResetState: PropTypes.func.isRequired,
    dispatchSetFormIsValidating: PropTypes.func.isRequired,
    dispatchOnMount: PropTypes.func.isRequired,
    dispatchCheckUsername: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    errorCode: undefined,
    formRecaptchaToken: undefined,
    recaptchaSiteKey: undefined,
    isRebrandEnabled: false,
  };

  constructor(props) {
    super(props);

    this.elementRef = null;
    this.dirty = false;

    const queryParams = UtilityHelper.parseQueryString(props.location.search);
    const redirectUrl = queryParams.redirectUrl || staticConstants.DEFAULT_LOGIN_LANDING_PAGE;
    const absPath = redirectUrl.replace(/^[/\\]*/, '/');

    this.redirectUrl = absPath;
  }

  handleNextButtonClick = () => {
    const { dispatchCheckUsername, formUsername } = this.props;

    dispatchCheckUsername(formUsername);
  };

  handleUsernameKeyPress = (event) => {
    const { formUsername } = this.props;

    if (event.key === 'Enter' && formUsername && formUsername.trim() !== '') {
      this.handleNextButtonClick();
    }
  };

  componentDidMount() {
    const { dispatchOnMount, dispatchSetFormField } = this.props;

    const username = cookieUtils.getCookie('lifion_user_id');

    if (username) {
      dispatchSetFormField('username', username);
    }

    dispatchOnMount(this.redirectUrl);
  }

  componentDidUpdate(prevProps) {
    const {
      isValidating,
      formUsername,
      formPassword,
      formRecaptchaToken,
      history,
      isAllValid,
      dispatchLogin,
      dispatchHidePasswordInputDisplay,
    } = this.props;

    if (prevProps.formUsername !== formUsername && formUsername.trim() === '') {
      dispatchHidePasswordInputDisplay(true);
    }

    if (this.shouldLogin(prevProps.isValidating, isValidating, isAllValid)) {
      dispatchLogin(formUsername, formPassword, formRecaptchaToken, this.redirectUrl, history);
    }
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  getErrorMessage = () => {
    const { errorCode } = this.props;

    switch (errorCode) {
      case supportedErrorCodes.INCORRECT_CREDS:
        return <TranslatedText textKey="INCORRECT_TRY_AGAIN" />;
      case supportedErrorCodes.TOO_MANY_ATTEMPTS:
        return <TranslatedText textKey="TOO_MANY_ATTEMPTS" />;
      case supportedErrorCodes.ASSOCIATE_SUSPENDED:
        return <TranslatedText textKey="ASSOCIATE_SUSPENDED" />;
      case supportedErrorCodes.CAPTCHA_MISSING:
        return <TranslatedText textKey="CAPTCHA_MISSING" />;
      default:
        return <TranslatedText textKey="SNAG_TRY_AGAIN" containHtml={true} />;
    }
  };

  handleValidationChange(field, validationResult) {
    const { dispatchSetFormFieldValidationResult } = this.props;

    dispatchSetFormFieldValidationResult(field, validationResult);
  }

  handleKeyPress = (event) => {
    const { dispatchSetFormIsValidating } = this.props;

    this.dirty = true;

    if (event.key === 'Enter') {
      dispatchSetFormIsValidating();
    }
  };

  handleChange(field, event) {
    const { dispatchSetFormField } = this.props;

    dispatchSetFormField(field, event.target.value);
  }

  handleUsernameChange = this.handleChange.bind(this, 'username');

  handlePasswordChange = this.handleChange.bind(this, 'password');

  handleRecaptchaTokenChange = this.props.dispatchSetFormField.bind(this, 'recaptchaToken');

  handleUsernameValidationChange = this.handleValidationChange.bind(this, 'username');

  handlePasswordValidationChange = this.handleValidationChange.bind(this, 'password');

  handleRecaptchaTokenValidationChange = this.handleValidationChange.bind(this, 'recaptchaToken');

  shouldLogin = (isFormSubmitted, isStillValidating, isAllValid) =>
    isFormSubmitted && !isStillValidating && isAllValid;

  render() {
    const {
      isCheckingAuth,
      isCheckingUsername,
      isValidating,
      formUsername,
      formPassword,
      formRecaptchaToken,
      isErrorVisible,
      dictionary,
      dispatchSetFormIsValidating,
      captchaRequired,
      recaptchaSiteKey,
      isLoggingIn,
      isAllValid,
      isAuthenticatedByNexo,
      showPasswordField,
      isRebrandEnabled,
    } = this.props;

    if (isCheckingAuth || isAuthenticatedByNexo) {
      return null;
    }

    const passwordRulesOverride = [
      new LengthRule(loginDictionary.get('RULE_FIELD_IS_REQUIRED', dictionary), 1, 255),
    ];

    const disableNextButton = !formUsername || formUsername.trim() === '';
    const disableLoginButton = !isAllValid || formPassword.trim() === '';

    return (
      <section
        className="login-page"
        ref={(node) => {
          this.elementRef = node;
        }}
      >
        <div className="flexLeft">
          <button
            className="infoButton"
            aria-label="Secure Information for User"
            type="button"
            aria-disabled="false"
            onMouseOver={() => {
              document.getElementById('tooltip').style.display = 'block';
            }}
            onMouseOut={() => {
              document.getElementById('tooltip').style.display = 'none';
            }}
            onFocus={() => {
              document.getElementById('tooltip').style.display = 'block';
            }}
            onBlur={() => {
              document.getElementById('tooltip').style.display = 'none';
            }}
          >
            <span>
              <SecureInfoIcon />
            </span>
            <span id="tooltip" className="tooltip">
              <TranslatedText textKey="SECURE_INFORMATION_DESCRIPTION" />
            </span>
          </button>
        </div>
        <div className="adplogo">
          <a
            href={translationUtils.getLifionByAdp()}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="ADP Logo"
          >
            <div className="adplogoImage" />
          </a>
        </div>
        <h1 className="login-page-welcome-title">
          <TranslatedText textKey="WELCOME_TO_ADP" isRebrandEnabled={isRebrandEnabled} />
        </h1>
        <div className="login-input-wrap">
          <div className="login-input-large">
            <TextInput
              forceValidationCheck={isValidating}
              handleValidationChange={this.handleUsernameValidationChange}
              onChange={this.handleUsernameChange}
              onKeyPress={this.handleUsernameKeyPress}
              value={formUsername}
              label={loginDictionary.get('USERNAME_LABEL', dictionary)}
              type="text"
              className="input-large"
              autoFocus={true}
              id="username-input"
              dataMetaId="username-input"
              autoComplete="username"
              validateOnChange={true}
              errorMessage={textInputDictionary.get('ERROR_MESSAGE', dictionary)}
              minLength={1}
            />
          </div>
        </div>
        {isCheckingUsername && (
          <div className="loader" data-meta-id="username-check-loader">
            <img src={SpinnerIcon} alt="Spinner Icon" />
          </div>
        )}
        <div className="login-input-wrap">
          <div className="login-input-large">
            <PasswordInput
              forceValidationCheck={isValidating}
              handleValidationChange={this.handlePasswordValidationChange}
              onChange={this.handlePasswordChange}
              onKeyPress={this.handleKeyPress}
              value={formPassword}
              label={loginDictionary.get('PASSWORD_LABEL', dictionary)}
              overrideRules={passwordRulesOverride}
              type="password"
              className={`input-large ${!showPasswordField ? 'hide-password' : ''}`}
              id="password-input"
              dataMetaId="password-input"
              autoComplete="current-password"
              showPasswordText={notValidatedPwInputDictionary.get('SHOW', dictionary)}
              hidePasswordText={notValidatedPwInputDictionary.get('HIDE', dictionary)}
              neverShowError={false}
              validateOnChange={true}
            />
          </div>
        </div>
        {isErrorVisible && <InlineMessage type="error">{this.getErrorMessage()}</InlineMessage>}
        <CaptchaInput
          forceValidationCheck={isValidating}
          captchaRequired={captchaRequired}
          recaptchaToken={formRecaptchaToken}
          recaptchaSiteKey={recaptchaSiteKey}
          isLoggingIn={isLoggingIn}
          handleRecaptchaTokenChange={this.handleRecaptchaTokenChange}
          handleValidationChange={this.handleRecaptchaTokenValidationChange}
        />
        <div className="buttons-links-container">
          <div className="links-section">
            <ButtonContainer className="sso-login-link">
              <Link
                to="/auth/sso-login/request"
                className="link-text"
                data-meta-id="sso-login-link"
              >
                <TranslatedText textKey="SSO_LOGIN" />
              </Link>
            </ButtonContainer>
            {showPasswordField && (
              <ButtonContainer className="reset-password-link">
                <Link
                  to="/auth/forgot-password/request"
                  className="link-text"
                  data-meta-id="forgot-password-link"
                >
                  <TranslatedText textKey="FORGOT_PASSWORD" />
                </Link>
              </ButtonContainer>
            )}
            {!showPasswordField && (
              <ButtonContainer className="reset-password-link">
                <Link
                  to="/auth/forgot-username/request"
                  className="link-text"
                  data-meta-id="forgot-username-or-password-link"
                >
                  <TranslatedText textKey="FORGOT_USER_ID_OR_PASSWORD" />
                </Link>
              </ButtonContainer>
            )}
          </div>
          <div className="buttons-section">
            {showPasswordField ? (
              <ButtonContainer className="button-custom-container">
                <PrimaryButton
                  onClick={dispatchSetFormIsValidating}
                  size="large"
                  disabled={disableLoginButton}
                  data-meta-id="log-in-button"
                  className="log-in-button"
                >
                  <TranslatedText textKey="BUTTON_LOG_IN" />
                </PrimaryButton>
              </ButtonContainer>
            ) : (
              <ButtonContainer className="button-custom-container">
                <PrimaryButton
                  onClick={this.handleNextButtonClick}
                  size="large"
                  data-meta-id="next-button"
                  className="next-button"
                  disabled={disableNextButton}
                >
                  <TranslatedText textKey="BUTTON_NEXT" />
                </PrimaryButton>
              </ButtonContainer>
            )}
          </div>
        </div>
        <div className="dark-line" />
        <ButtonContainer className="registration-email-container">
          <Link
            to="/auth/self-registration/request"
            className="link-text"
            data-meta-id="self-registration"
          >
            <TranslatedText textKey="SEND_REGISTRATION_EMAIL" />
          </Link>
        </ButtonContainer>
      </section>
    );
  }
}

const validatedFields = ['username', 'password', 'recaptchaToken'];

function mapStateToProps(state) {
  return {
    formUsername: state.login.form.username.value,
    formPassword: state.login.form.password.value,
    formRecaptchaToken: state.login.form.recaptchaToken.value,
    isErrorVisible: state.login.main.error.isVisible,
    errorCode: state.login.main.error.code,
    isAllValid: validatedFields.every((key) => state.login.form[key].isValid),
    isValidating: validatedFields.some((key) => state.login.form[key].isValidating),
    isCheckingAuth: state.login.main.isCheckingAuth,
    isCheckingUsername: state.login.main.isCheckingUsername,
    isAuthenticatedByNexo: state.login.main.isAuthenticatedByNexo,
    isLoggingIn: state.login.main.isLoggingIn,
    dictionary: state.translation.main.dictionary,
    captchaRequired: state.login.main.captchaRequired,
    recaptchaSiteKey: state.login.main.recaptchaSiteKey,
    showPasswordField: state.login.main.showPasswordField,
    isRebrandEnabled: state.me.main.isRebrandEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchSetFormIsValidating() {
      dispatch(actionCreator.setFormIsValidating());
    },

    dispatchSetFormFieldValidationResult(field, validationResult) {
      dispatch(actionCreator.setFormFieldValidationResult(field, validationResult));
    },

    dispatchLogin(username, password, recaptchaToken, redirectUrl, history) {
      dispatch(actionCreator.login(username, password, recaptchaToken, redirectUrl, history));
    },

    dispatchHidePasswordInputDisplay(hidePassword) {
      dispatch(actionCreator.hidePasswordInputDisplay(hidePassword));
    },

    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchOnMount(redirectUrl) {
      dispatch(actionCreator.redirectIfLoggedIn(redirectUrl));
    },

    dispatchCheckUsername(username) {
      dispatch(actionCreator.checkUsername(username));
    },
  };
}

export { Login as UnwrappedLogin };
export default connect(mapStateToProps, mapDispatchToProps)(Login);
