import meActionCreator from '../../actioncreators/me';
import UtilityHelper from 'utils/helpers/';
import namespaceTranslatedText from 'containers/TranslatedText/';
import { ErrorPage } from '@idm/ui-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const TranslatedText = namespaceTranslatedText('UNAUTHORIZED_ERROR');

class UnauthorizedErrorPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    isRebrandEnabled: PropTypes.bool.isRequired,
    dispatchOnMount: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { dispatchOnMount } = this.props;

    dispatchOnMount();
  }

  errorMessage(traceId, isRebrandEnabled) {
    return (
      <div className="idm-error-message forbidden-error">
        <div className="idm-error-header-text">
          <TranslatedText textKey="DONT_HAVE_PERMISSION" />
        </div>
        <p className="idm-error-desc-text">
          <TranslatedText textKey="NOT_INTENDED_FOR_YOU" />
        </p>
        <p className="idm-error-desc-text">
          {isRebrandEnabled ? (
            <TranslatedText textKey="NEW_IMMEDIATE_ASSISTANCE" containHtml={true} />
          ) : (
            <TranslatedText textKey="IMMEDIATE_ASSISTANCE" containHtml={true} />
          )}
          {traceId && (
            <TranslatedText textKey="PROVIDE_TRACE_ID" containHtml={true} params={{ traceId }} />
          )}
        </p>

        {traceId && (
          <a href="/auth/login">
            <TranslatedText textKey="BACK_BUTTON" containHtml={true} />
          </a>
        )}
      </div>
    );
  }

  render() {
    const { location, isRebrandEnabled } = this.props;

    const queryParams = UtilityHelper.parseQueryString(location.search);
    const { traceId } = queryParams;

    return (
      <ErrorPage
        code="403"
        message={this.errorMessage(traceId, isRebrandEnabled)}
        isRebrandEnabled={isRebrandEnabled}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    isRebrandEnabled: state.me.main.isRebrandEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchOnMount() {
      dispatch(meActionCreator.getRebrandFeatureFlagStatus());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedErrorPage);
